<template>
  <b-card
    v-if="getUser.role.slug === 'super-administrateur'"
    no-body
  >
    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Per Page -->
        <b-col
          cols="12"
          md="6"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <b-button
            v-b-modal.modal-new-role
            variant="primary"
          >
            <span class="text-nowrap">Ajouter</span>
          </b-button>

          <b-modal
            id="modal-new-role"
            cancel-variant="outline-secondary"
            hide-footer
            centered
            no-close-on-backdrop
            no-close-on-esc
            title="Ajout d'un rôle"
          >
            <validation-observer
              #default="{}"
              ref="addNewRoleForm"
            >
              <!-- Form -->
              <b-form
                class="p-2"
                autocomplete="off"
                @reset.prevent="hideModal"
                @submit.prevent="applyAddNewRoleAction"
              >
                <!-- Email -->
                <validation-provider
                  #default="{ errors }"
                  name="description"
                  rules="required"
                >
                  <b-form-group
                    label="Désignation"
                    label-for="description"
                  >
                    <b-form-input
                      id="description"
                      v-model="payload.name"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      trim
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  #default="{ errors }"
                  name="description"
                  rules="required"
                >
                  <b-form-group
                    label="Description"
                    label-for="textarea-default"
                  >
                    <b-form-textarea
                      id="textarea-default"
                      v-model="payload.description"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Décrivez le rôle"
                      rows="3"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>

                <!-- Form Actions -->
                <div class="d-flex mt-2">
                  <b-button
                    :disabled="isAddRoleLoading"
                    variant="primary"
                    class="mr-2"
                    type="submit"
                  >
                    <div v-if="isAddRoleLoading">
                      <span> Chargement ... </span>
                      <b-spinner small />
                    </div>
                    <span v-else>Enregistrer</span>
                  </b-button>
                  <b-button
                    type="reset"
                    variant="outline-secondary"
                  >
                    Annuler
                  </b-button>
                </div>
              </b-form>
            </validation-observer>
          </b-modal>
          <b-modal
            id="modal-update-role"
            cancel-variant="outline-secondary"
            hide-footer
            centered
            no-close-on-backdrop
            no-close-on-esc
            title="Modification d'un rôle"
          >
            <validation-observer
              #default="{}"
              ref="updateRoleForm"
            >
              <!-- Form -->
              <b-form
                class="p-2"
                autocomplete="off"
                @reset.prevent="hideUpdateModal"
                @submit.prevent="applyUpdateRoleAction"
              >
                <!-- Email -->
                <validation-provider
                  #default="{ errors }"
                  name="description"
                  rules="required"
                >
                  <b-form-group
                    label="Désignation"
                    label-for="description"
                  >
                    <b-form-input
                      id="description"
                      v-model="payload.name"
                      type="text"
                      :state="errors.length > 0 ? false : null"
                      trim
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>

                <validation-provider
                  #default="{ errors }"
                  name="description"
                  rules="required"
                >
                  <b-form-group
                    label="Description"
                    label-for="textarea-default"
                  >
                    <b-form-textarea
                      id="textarea-default"
                      v-model="payload.description"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Décrivez le rôle"
                      rows="3"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </b-form-group>
                </validation-provider>

                <!-- Form Actions -->
                <div class="d-flex mt-2">
                  <b-button
                    :disabled="isAddRoleLoading"
                    variant="primary"
                    class="mr-2"
                    type="submit"
                  >
                    <div v-if="isUpdateRoleLoading">
                      <span> Chargement ... </span>
                      <b-spinner small />
                    </div>
                    <span v-else>Modifier</span>
                  </b-button>
                  <b-button
                    type="reset"
                    variant="outline-secondary"
                  >
                    Annuler
                  </b-button>
                </div>
              </b-form>
            </validation-observer>
          </b-modal>
        </b-col>

        <!-- Search -->
        <b-col
          cols="12"
          md="6"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              class="d-inline-block mr-1"
              placeholder="Rechercher..."
            />
          </div>
        </b-col>
      </b-row>
    </div>
    <b-table
      ref="refInvoiceListTable"
      :items="tableItems"
      responsive
      :busy="isLoading"
      :fields="tableColumns"
      primary-key="id"
      :sort-by.sync="sortBy"
      show-empty
      empty-text="Liste vide"
      :sort-desc.sync="isSortDirDesc"
      class="position-relative"
    >
      <template #table-busy>
        <div class="text-center text-info my-2">
          <b-spinner class="align-middle" /> &nbsp;
          <strong>Chargement...</strong>
        </div>
      </template>
      <!-- Column: Invoice Status -->

      <!-- Column: Actions -->
      <template #cell(actions)="data">
        <div class="text-nowrap">
          <!-- Dropdown -->
          <b-dropdown
            variant="link"
            toggle-class="p-0"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="showUpdateRoleModal(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Modifier</span>
            </b-dropdown-item>

            <b-dropdown-item @click="applyDeleteRoleAction(data.item.id)">
              <feather-icon
                variant="danger"
                icon="TrashIcon"
              />
              <span class="align-middle ml-50">Supprimer</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </template>
    </b-table>
    <div class="mx-2 mb-2">
      <b-row>
        <b-col
          cols="12"
          sm="6"
          class="
            d-flex
            align-items-center
            justify-content-center justify-content-sm-start
          "
        >
          <span
            class="text-muted"
          >Affichage de {{ dataMeta.from }} à {{ dataMeta.to }} sur
            {{ dataMeta.of }} lignes</span>
        </b-col>
        <!-- Pagination -->
        <b-col
          cols="12"
          sm="6"
          class="
            d-flex
            align-items-center
            justify-content-center justify-content-sm-end
          "
        >
          <b-pagination
            v-model="currentPage"
            :total-rows="totalItems"
            :per-page="perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon
                icon="ChevronLeftIcon"
                size="18"
              />
            </template>
            <template #next-text>
              <feather-icon
                icon="ChevronRightIcon"
                size="18"
              />
            </template>
          </b-pagination>
        </b-col>
      </b-row>
    </div>
  </b-card>
  <div v-else>
    <not-authorized />
  </div>
</template>

<script>
import {
  // BTab,
  BCard,
  BRow,
  BForm,
  BCol,
  BFormInput,
  BFormGroup,
  // BFormInvalidFeedback,
  BButton,
  BTable,
  // BMedia,
  BModal,
  // BAvatar,
  // BLink,
  // BBadge,
  BDropdown,
  BDropdownItem,
  BFormTextarea,
  BPagination,
  // BTooltip,
  BSpinner,
  // BTabs,
  // BAlert,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
// import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required, alphaNum, email } from '@validations'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    // BTab,
    ValidationProvider,
    ValidationObserver,
    BModal,
    // BTabs,
    BFormGroup,
    BFormTextarea,
    // BFormInvalidFeedback,
    BCard,
    // BAlert,
    // BLink,
    BRow,
    BForm,
    BCol,
    BFormInput,
    BButton,
    BTable,
    // BMedia,
    // BAvatar,
    // BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    // BTooltip,
    BSpinner,
    // vSelect,
  },
  data() {
    return {
      tableItems: [],
      isLoading: false,
      isAddRoleLoading: false,
      isUpdateRoleLoading: false,
      roleId: null,
      payload: {
        name: '',
        description: '',
      },
      required,
      alphaNum,
      email,
      tableColumns: [
        { key: 'name', label: 'Désignation', sortable: true },
        { key: 'description', label: 'Description', sortable: true },
        { key: 'actions' },
      ],
      perPage: 10,
      totalItems: 0,
      sidebarIsActive: false,
      currentPage: 1,
      perPageOptions: [10, 25, 50, 100],
      searchQuery: '',
      sortBy: 'id',
      isSortDirDesc: true,
      statusFilter: null,
      avatarText,
    }
  },
  computed: {
    ...mapGetters('access', ['getRoles']),
    ...mapGetters('auth', ['getUser']),

    dataMeta() {
      const localItemsCount = this.$refs.refInvoiceListTable
        ? this.$refs.refInvoiceListTable.localItems.length
        : 0
      return {
        from: this.perPage * (this.currentPage - 1) + (localItemsCount ? 1 : 0),
        to: this.perPage * (this.currentPage - 1) + localItemsCount,
        of: this.totalItems,
      }
    },
  },
  watch: {
    statusFilter() {
      this.refetchData()
    },
    currentPage() {
      this.refetchData()
    },
    perPage() {
      this.refetchData()
    },
    searchQuery() {
      this.refetchData()
    },
  },
  mounted() {
    if (this.getUser.role.slug === 'super-administrateur') { this.applyGetRolesAction() }
  },

  methods: {
    ...mapActions('access', [
      'getRolesAction',
      'addNewRoleAction',
      'updateRoleAction',
      'deleteRoleAction',
    ]),
    showUpdateRoleModal(data) {
      this.$bvModal.show('modal-update-role')
      this.payload.name = data.name
      this.payload.description = data.description
      this.roleId = data.id
    },
    hideModal() {
      this.$bvModal.hide('modal-new-role')
      this.payload = {
        name: '',
        description: '',
      }
    },
    hideUpdateModal() {
      this.$bvModal.hide('modal-update-role')
      this.payload = {
        name: '',
        description: '',
      }
    },
    refetchData() {
      const params = {
        q: this.searchQuery,
        perPage: this.perPage,
        page: this.currentPage,
        sortBy: this.sortBy,
        sortDesc: this.isSortDirDesc,
        status: this.statusFilter,
      }
      const { items, total } = this.getData(params)
      this.tableItems = items
      this.total = total
      this.totalItems = this.total
    },
    resolveProStatusVariant: status => {
      if (status === true) return 'success'
      return 'warning'
    },
    resolveProStatusText: status => {
      if (status === true) return 'Validé'
      return 'En attente de validation'
    },
    sortCompare: key => (a, b) => {
      const fieldA = a[key]
      const fieldB = b[key]

      let comparison = 0
      if (fieldA > fieldB) {
        comparison = 1
      } else if (fieldA < fieldB) {
        comparison = -1
      }
      return comparison
    },
    paginateArray: (array, perPage, page) => array.slice((page - 1) * perPage, page * perPage),
    applyGetRolesAction() {
      this.isLoading = true
      this.getRolesAction()
        .then(() => {
          const params = {
            q: this.searchQuery,
            perPage: this.perPage,
            page: this.currentPage,
            sortBy: this.sortBy,
            sortDesc: this.isSortDirDesc,
            status: this.statusFilter,
          }
          const { items, total } = this.getData(params)
          this.tableItems = items
          this.total = total
          this.totalItems = this.total
          this.isLoading = false
        })
        .catch(() => {})
    },
    applyAddNewRoleAction() {
      this.$refs.addNewRoleForm.validate().then(success => {
        if (success) {
          // eslint-disable-next-line no-unused-expressions
          this.isAddRoleLoading = true
          this.addNewRoleAction(this.payload)
            .then(response => {
              this.isAddRoleLoading = false
              this.applyGetRolesAction()
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Succès',
                    icon: 'CheckIcon',
                    text: response.message,
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                },
              )
              this.$bvModal.hide('modal-new-role')
              this.payload = {
                name: '',
                description: '',
              }
            })
            .catch(error => {
              console.log(error)
              this.isAddRoleLoading = false
            })
        }
      })
    },
    applyUpdateRoleAction() {
      this.$refs.updateRoleForm.validate().then(success => {
        if (success) {
          // eslint-disable-next-line no-unused-expressions
          this.isUpdateRoleLoading = true
          this.updateRoleAction({ payload: this.payload, id: this.roleId })
            .then(response => {
              this.isUpdateRoleLoading = false
              this.applyGetRolesAction()
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Succès',
                    icon: 'CheckIcon',
                    text: response.message,
                    variant: 'success',
                  },
                },
                {
                  position: 'top-center',
                },
              )
              this.$bvModal.hide('modal-update-role')
              this.payload = {
                name: '',
                description: '',
              }
            })
            .catch(error => {
              console.log(error)
              this.isUpdateRoleLoading = false
            })
        }
      })
    },
    applyDeleteRoleAction(id) {
      this.$swal({
        title: 'Suppression',
        text: 'Êtes-vous sûr de vouloir supprimer cet rôle?',
        icon: 'warning',
        showCancelButton: true,
        showLoaderOnConfirm: true,
        cancelButtonText: 'Non',
        allowOutsideClick: false,
        confirmButtonText: 'Oui',
        customClass: {
          confirmButton: 'btn btn-warning',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
        // eslint-disable-next-line no-return-await
        preConfirm: async () => await this.deleteRoleAction(id),
      })
        .then(response => {
          if (response.value && response.value.status === 200) {
            this.applyGetRolesAction()
            this.$toast(
              {
                component: ToastificationContent,
                props: {
                  title: 'Succès',
                  icon: 'CheckIcon',
                  text: response.value.data.message,
                  variant: 'success',
                },
              },
              {
                position: 'top-center',
              },
            )
          }
        })
        .catch(error => {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Erreur',
                icon: 'AlertTriangleIcon',
                text: error,
                variant: 'danger',
              },
            },
            {
              position: 'top-center',
            },
          )
        })
    },
    getData(params) {
      const data = this.getRoles
      // eslint-disable-next-line object-curly-newline
      const {
        q = '',
        perPage = 10,
        page = 1,
        sortBy = 'id',
        sortDesc = false,
      } = params
      /* eslint-enable */

      const queryLowered = q.toLowerCase()
      const filteredData = data.filter(invoice =>
        /* eslint-disable operator-linebreak, implicit-arrow-linebreak */
        invoice.name.toLowerCase().includes(queryLowered))
      /* eslint-enable  */

      const sortedData = filteredData.sort(this.sortCompare(sortBy))
      if (sortDesc) sortedData.reverse()

      return {
        items: this.paginateArray(sortedData, perPage, page),
        total: filteredData.length,
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
